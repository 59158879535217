import React from "react"
import { Link } from "gatsby"




class Newsletter extends React.Component {

  componentDidMount() {

  }




render() {


  return (
  <>
  <iframe id="p-w" title="particles" src="/particles.html" loading="lazy" frameBorder="0" style={{width:'100%',minHeight:'300px',maxHeight:'350px',position:'relative',zIndex:'1'}}></iframe>
  <div id="newsletter" style={{padding:'0 3em',marginTop:'-250px'}}>
  <div className="columns">

    <div className="column" style={{textAlign:'left'}}>
    <h2 className="is-size-2" style={{color:'black',position:'relative',zIndex:'2'}}>Newsletter</h2>
    </div>


    <div className="column" style={{textAlign:'left',color:'white',position:'relative',zIndex:'2'}}>
    Subskrybuj nasz newsletter, <br />
    i bądź na bieżąco!
    </div>

    <div className="column" style={{textAlign:'left',color:'white',position:'relative',zIndex:'2'}}>
    <form name="newsletter"
      method="post"
      action="/kontakt/sukces/"
      data-netlify="true"
      data-netlify-honeypot="bot-field">
    <div className="control" style={{display:'flex'}}>
    <input placeholder="jhon@doe.com" required="true" name="adres email" className="input" type="mail" style={{maxWidth:'65%',minWidth:'125px'}} />
    <button className="button rexer-button is-size-6" type="submit" style={{margin:'0'}}>Subskrybuj</button>

              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="newsletter" />
              <div hidden>
                <label>
                  Don’t fill this out:{' '}

                  <input name="bot-field"
                  onChange={this.handleChange}
                  />
                </label>
              </div>
    </div>
      <div className="control">
      <input required="true" type="checkbox" id="privacyN" name="privacy" value="true"/>&nbsp;&nbsp;
      <label style={{fontSize: '10px',lineHeight:'10px',color:'black'}}  htmlFor="privacyN"> Wyrażam zgodę na przetwarzanie moich danych zgodnie z naszą <Link style={{color:'white'}} to="/polityka-prywatnosci/"><b>polityką prywatności</b></Link>.</label>
      </div>
      </form>
    </div>



  </div>
  </div>
  </>
)
}


}

export default Newsletter
